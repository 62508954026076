export const convictions = [
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },
  {
    defendant: 'Albion Building Consultant Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },

  {
    defendant: 'Farida Haque',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge: 'ONHWPA - Sec 6 - Illegally acted as a Vendor of a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: 'Zamal Hossain',
    link: 'https://obd.hcraontario.ca/profile/B47109',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'October 15, 2020',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'February 11, 2022',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },

  {
    defendant: 'Mark Mior',
    link: 'https://obd.hcraontario.ca/unlicensed/100610873',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'March 3, 2020',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'February 6, 2023',
    result: 'Convicted Fine',
    underAppeal: false
  },
  {
    defendant: 'Mazenga Building Group Ltd.',
    link: 'https://obd.hcraontario.ca/profile/B41801',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'March 31, 2022',
    result: 'Convicted Fine',
    underAppeal: false
  },

  {
    defendant: '6736238 Canada Inc.',
    link: 'https://obd.hcraontario.ca/profile/B39871',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'September 18, 2019',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'June 16, 2023',
    result: 'Convicted Fine',
    fineAmount: '$25,000.00',
    underAppeal: false
  },
  {
    defendant: 'Mukunth Rajadurai',
    link: 'https://obd.hcraontario.ca/profile/B39871',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'September 18, 2019',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'June 16, 2023',
    result: 'Convicted Fine',
    fineAmount: '$12,500.00',
    underAppeal: false
  },
  {
    defendant: 'Akram Syed Mohammed Hossain',
    link: 'https://obd.hcraontario.ca/unlicensed/100321237',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'February 1, 2023',
    charge: 'ONHWPA - Sec 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'September 8, 2023',
    result: 'Convicted Fine',
    fineAmount: '$7,500.00',
    underAppeal: false
  },
  {
    defendant: 'Akram Syed Mohammed Hossain',
    link: 'https://obd.hcraontario.ca/unlicensed/100321237',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'February 1, 2023',
    charge: 'ONHWPA - Sec 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'September 8, 2023',
    result: 'Convicted Fine',
    fineAmount: '$7,500.00',
    underAppeal: false
  },
  {
    defendant: 'Nasrin Jahan',
    link: 'https://obd.hcraontario.ca/unlicensed/600012296',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'February 1, 2023',
    charge: 'ONHWPA - Sec 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'September 8, 2023',
    result: 'Convicted Suspended Sentence',
    underAppeal: false
  },
  {
    defendant: '2252759 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Simcoe, ON',
    chargeDate: 'July 5, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Convicted Fine',
    fineAmount: '$12,500.00'
  },
  {
    defendant: 'Caruk-Hall Construction Inc.',
    link: 'https://obd.hcraontario.ca/profile/B45764',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge: 'ONHWPA - Sec 6 - Illegally acted as a Builder of a New Home',
    convictionDate: 'October 13, 2023',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: true
  },
  {
    defendant: 'Caruk-Hall Construction Inc.',
    link: 'https://obd.hcraontario.ca/profile/B45764',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge: 'ONHWPA - Sec 12 - Failed to Enrol a New Home',
    convictionDate: 'October 13, 2023',
    result: 'Convicted Suspended Sentence',
    underAppeal: true
  },
  {
    defendant: 'James Caruk',
    link: 'https://obd.hcraontario.ca/profile/B45764',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal Builder of a new home',
    convictionDate: 'October 13, 2023',
    result: 'Convicted Fine',
    fineAmount: '$6,250.00',
    underAppeal: true
  },
  {
    defendant: 'James Caruk',
    link: 'https://obd.hcraontario.ca/profile/B45764',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'October 13, 2023',
    result: 'Convicted Fine',
    fineAmount: '$6,250.00',
    underAppeal: true
  },
  {
    defendant: 'Daniel Hall',
    link: 'https://obd.hcraontario.ca/profile/B45764',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal Builder of a new home',
    convictionDate: 'October 13, 2023',
    result: 'Convicted Fine',
    fineAmount: '$6,250.00',
    underAppeal: true
  },
  {
    defendant: 'Daniel Hall',
    link: 'https://obd.hcraontario.ca/profile/B45764',
    dLocation: 'Toronto, ON',
    oLocation: 'Toronto, ON',
    chargeDate: 'August 6, 2019',
    charge:
      'ONHWPA - Sec 12 - Officer/Director of a Company who Failed to Enrol a New Home',
    convictionDate: 'October 13, 2023',
    result: 'Convicted Fine',
    fineAmount: '$6,250.00',
    underAppeal: true
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Withdrawn'
  },
  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'September 15, 2023',
    result: 'Convicted Fine',
    fineAmount: '$15,625.00',
    underAppeal: false
  },

  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge:
      'NHCLA - Section 61 - Fail to produce evidence described in a warrant',
    convictionDate: 'September 15, 2023',
    result: 'Convicted Fine',
    fineAmount: '$18,750.00',
    underAppeal: false
  },

  {
    defendant: 'Ideal (BC) Developments Inc.',
    link: 'https://obd.hcraontario.ca/unlicensed/B48282',
    dLocation: 'Markham, ON',
    oLocation: 'Richmond Hill, ON',
    chargeDate: 'August 4, 2021',
    charge: '',
    convictionDate: 'September 15, 2023',
    result: 'Restitution Order',
    fineAmount: '$150,000.00'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: 'Christopher Lamb',
    link: 'https://obd.hcraontario.ca/profile/B46897',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge:
      'ONHWPA - Sec 6 - Officer/Director of an illegal vendor of a new home',

    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$5,769.23'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: '1970175 Ontario Inc.',
    link: 'https://obd.hcraontario.ca/profile/B47828',
    dLocation: 'St. Catharines, ON',
    oLocation: 'Niagara Falls, ON',
    chargeDate: 'November 8, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Vendor of a new home',
    convictionDate: 'December 4, 2023',
    result: 'Convicted Fine',
    fineAmount: '$11.00'
  },
  {
    defendant: 'Parmjeet Gill O/A Platinum Custom Homes Group',
    link: 'https://obd.hcraontario.ca/unlicensed/B48832',
    dLocation: 'Missisauga, ON',
    oLocation: 'Missisauga, ON',
    chargeDate: 'January 13, 2021',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home',
    convictionDate: 'March 1, 2024',
    result: 'Convicted Fine',
    fineAmount: '$2,500.00'
  },
  {
    defendant: 'Parmjeet Gill O/A Platinum Custom Homes Group',
    link: 'https://obd.hcraontario.ca/unlicensed/B48832',
    dLocation: 'Missisauga, ON',
    oLocation: 'Missisauga, ON',
    chargeDate: 'January 13, 2021',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home',
    convictionDate: 'March 1, 2024',
    result: 'Convicted Fine',
    fineAmount: '$2,187.50'
  }
]
