export const charges = [
  {
    defendant: 'Syed Nasir Ali Shah',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Bolton, ON',
    oLocation: 'Simcoe, ON',
    chargeDate: 'July 5, 2021',
    charge:
      'ONHWPA - Section 6 - Officer/Director of a Company who Illegally acted as a Vendor of a New Home'
  },
  {
    defendant: 'Exquisite Bay Development Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor NHCLA'
  },
  {
    defendant: 'Exquisite Bay Development Inc.',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor NHCLA'
  },
  {
    defendant: 'Ahmed Raza Yousuf',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor Officer/Director NHCLA'
  },
  {
    defendant: 'Ahmed Raza Yousuf',
    link: 'https://obd.hcraontario.ca/profile/B44757',
    dLocation: 'Mississauga, ON',
    oLocation: 'Mississauga, ON',
    chargeDate: 'January 19, 2024',
    charge: 'Section 37(1) – Vendor Officer/Director NHCLA'
  },
  {
    defendant: 'Matoma Corporation',
    link: 'https://obd.hcraontario.ca/unlicensed/B46630',
    dLocation: 'Barrie, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home'
  },
  {
    defendant: 'Matoma Corporation',
    link: 'https://obd.hcraontario.ca/unlicensed/B46630',
    dLocation: 'Barrie, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  },
  {
    defendant: 'Tomas Joseph Pavlovic',
    link: 'https://obd.hcraontario.ca/unlicensed/100466551',
    dLocation: 'Mississauga, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge:
      'ONHWPA - Section 6 - Officer/Director of a Company who Illegally acted as a Builder of a New Home'
  },
  {
    defendant: 'Tomas Joseph Pavlovic',
    link: 'https://obd.hcraontario.ca/unlicensed/100466551',
    dLocation: 'Mississauga, ON',
    oLocation: 'The Blue Mountains, ON',
    chargeDate: 'January 16, 2024',
    charge:
      'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  }

  //   defendant: 'Al-Leem Kassam o/a Creative Design & Construction',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B61137',
  //   dLocation: 'Richmond Hill, ON',
  //   oLocation: 'Port McNicoll, ON',
  //   chargeDate: 'November 21, 2023',
  //   charge: 'Section 37(2) – Builder NHCLA'
  // },
  // {
  //   defendant: 'Al-Leem Kassam o/a Creative Design & Construction',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B61137',
  //   dLocation: 'Richmond Hill, ON',
  //   oLocation: 'Port McNicoll, ON',
  //   chargeDate: 'November 21, 2023',
  //   charge: 'Section 37(2) – Builder NHCLA'
  // },
  // {
  //   defendant: 'Al-Leem Kassam o/a Creative Design & Construction',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B61137',
  //   dLocation: 'Richmond Hill, ON',
  //   oLocation: 'Port McNicoll, ON',
  //   chargeDate: 'November 21, 2023',
  //   charge: 'Section 71(1)(c) – Fails to comply with any order NHCLA'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'ONHWPA - Section 6 - Illegally acted as a Builder of a new home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'ONHWPA - Section 12 - Failed to Enrol a New Home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'Section 37(2) – Builder NHCLA'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 10.2(3) - Builder Fail to Enrol (enter into contract)'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 6 - Officer/Director of a Company who Illegally acted as a Builder of a New Home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 12 - Officer/Director of a Company who Failed to Enrol a New Home'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge: 'Section 37(2) – Builder O/D NHCLA'
  // },
  // {
  //   defendant: 'Lischkoff Build Design Ltd.',
  //   link: 'https://devobd2020.azurewebsites.net/unlicensed/B62919',
  //   dLocation: 'Utterson, ON',
  //   oLocation: 'Township of Chatsworth, ON',
  //   chargeDate: 'February 6, 2024',
  //   charge:
  //     'ONHWPA - Section 10.2(3) - Officer/Director Builder Fail to Enrol (Enter into Contract)'
  // }
]
