// SORT ASC
// params: arr, str(key used to sort)
export const sortAsc = (arr, key) =>
  arr.sort((a, b) => {
    if (a[key] < b[key]) return -1
    if (a[key] > b[key]) return 1
    else if (a[key] === null) return 1
    else if (b[key] === null) return -1
    else return 0
  })

// SORT DESC
// params: arr, str(key used to sort)
export const sortDesc = (arr, key) =>
  arr.sort((a, b) => {
    if (a[key] < b[key]) return 1
    else if (a[key] > b[key]) return -1
    else if (a[key] === null) return 1
    else if (b[key] === null) return -1
    else return 0
  })

// getPaginatedResults
// params: arr, active page #
// return: section of original array
export const getPaginatedResults = (arr, page) => {
  const numResults = 10 //TODO have this set in state?
  const first = (page - 1) * numResults
  const last = first + numResults
  return arr.slice(first, last)
}

export const orderByDate = (arr, key) =>
  arr.sort((a, b) => {
    const dateA = new Date(a[key]).getTime()
    const dateB = new Date(b[key]).getTime()
    // return dateA.getTime() - dateB.getTime()
    if (dateA < dateB) return 1
    else if (dateA > dateB) return -1
    else if (dateA === null) return 1
    else if (dateB === null) return -1
    else return 0
  })
