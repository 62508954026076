import React from 'react'
import componentStyles from './PaginationNav.module.scss'
import ClassNames from 'classnames'
import {
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleDoubleRight
} from 'react-icons/fa'

const PaginationNav = ({ currentPage, clickEvt, resultsLength, ...props }) => {
  const numPages = Math.ceil(resultsLength / 10)

  const getPageItems = () => {
    let pageItems = []
    const maxNavLength = 5
    let firstPage, lastPage
    if (numPages <= maxNavLength) {
      // number pages less than max, so show all pages
      firstPage = 0
      lastPage = numPages
    } else {
      // calculate start and end pages, if num pages more than max
      if (parseInt(currentPage) <= 2) {
        // currentPage near start
        firstPage = 0
        lastPage = maxNavLength
      } else if (parseInt(currentPage) + 2 >= numPages) {
        // current page near the end
        firstPage = numPages - maxNavLength
        lastPage = numPages
      } else {
        // current page somewhere in the middle
        firstPage = parseInt(currentPage) - 3
        lastPage = parseInt(currentPage) + 2
      }
    }
    for (let i = firstPage; i < lastPage; i++) {
      let classes =
        i + 1 === parseInt(currentPage)
          ? ClassNames(componentStyles.numberedLink, componentStyles.active)
          : componentStyles.numberedLink
      pageItems.push(
        <li className={classes} key={i + 1} role='none'>
          <button role='menuitem' onClick={() => clickEvt(i + 1)}>
            {i + 1}
          </button>
        </li>
      )
    }
    return pageItems
  }

  return (
    <nav className={componentStyles.paginationNav}>
      <ul role='menubar'>
        {parseInt(currentPage) === 1 ? (
          <li role='none' className={componentStyles.disabled}>
            <FaAngleDoubleLeft />{' '}
            <span className={componentStyles.textLabel}>First</span>
          </li>
        ) : (
          <li role='none'>
            <button onClick={() => clickEvt(1)}>
              <FaAngleDoubleLeft />{' '}
              <span className={componentStyles.textLabel}>First</span>
            </button>
          </li>
        )}

        {currentPage === 1 ? (
          <li role='none' className={componentStyles.disabled}>
            <FaAngleLeft />{' '}
            <span className={componentStyles.textLabel}>Prev</span>
          </li>
        ) : (
          <li role='none'>
            <button onClick={() => clickEvt(parseInt(currentPage) - 1)}>
              <FaAngleLeft />{' '}
              <span className={componentStyles.textLabel}>Prev</span>
            </button>
          </li>
        )}

        {getPageItems()}

        {parseInt(currentPage) === numPages ? (
          <li role='none' className={componentStyles.disabled}>
            <span className={componentStyles.textLabel}>Next</span>{' '}
            <FaAngleRight />
          </li>
        ) : (
          <li role='none'>
            <button onClick={() => clickEvt(parseInt(currentPage) + 1)}>
              <span className={componentStyles.textLabel}>Next</span>{' '}
              <FaAngleRight />
            </button>
          </li>
        )}

        {parseInt(currentPage) === numPages ? (
          <li role='none' className={componentStyles.disabled}>
            <span className={componentStyles.textLabel}>Last</span>{' '}
            <FaAngleDoubleRight />
          </li>
        ) : (
          <li role='none'>
            <button onClick={() => clickEvt(numPages)}>
              <span className={componentStyles.textLabel}>Last</span>{' '}
              <FaAngleDoubleRight />
            </button>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default PaginationNav
